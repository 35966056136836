import React from "react"
import styled from 'styled-components'
import { useQuery } from 'react-query'
import {
  ButtonOutline, ButtonGreen, Flex,
  GreenBarSection, HeaderBanner,
  Text, WhiteSection, Vis, Images
} from '../components/common'
import Layout from "../components/layout"
// import Step4HeroSvg from '../images/Step4Hero.svg'
import Step4HeroSvg from '../images/connect_your_bank.svg'
import api from "../utils/api"
import { Breadcrumb } from "../components/Breadcrumb"
import screenListen from '../utils/screenListener'
import { navTo } from '../utils/navigation'
import GlobalStoreContext from '../components/GlobalContext'

const ImageHolder = styled.img`
  width: 90%;
  object-fit: contain;
`

const PreWrap = styled.pre`
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
`

const MobileHeroSection = () => {
  const { width } = screenListen()
  let baseHeight = 452
  if (width < 768 && width) {
    const scale = width * 0.4
    baseHeight = scale
  }
  return (
    <WhiteSection flex="col nogrow center">
      <ImageHolder alt='' src={Step4HeroSvg} style={{ height: `${baseHeight}px` }} />
    </WhiteSection>
  )
}

// outter container set to relative so the Child can have an absolute position
const RelativeFlexMobile = styled(Flex)`
flex-direction: column;
position: relative;
`
//  This is the work around for iOS fullscreen issue
const MobileFullPageAbsoluteDiv = styled.div`
display:flex;
flex-direction:column;
top:0;
right:0;
left:0;
bottom:0;
position:absolute;
`

const ErrorBootstrapSection = styled.div`
  margin: 20px 18px;
`

const ButtonDelay = (navigateFn, payload) => {
  setTimeout(() => {
    if (payload) {
      navigateFn(payload)
    } else {
      navigateFn()
    }
  }, 300)
}

const DataHolderStatusErrorModal = ({ message, isModalOpen = true, fnCloseModal, fnProccedOn }) => {

  const ButtonOutlineNoPad = styled(ButtonOutline)`
  &:after {
    padding: 0% !important;
  }
  `
  const ButtonGreenNoPad = styled(ButtonGreen)`
  &:after {
    padding: 0% !important;
  }
  `

  const goBackToSelectBank = () => {
    fnCloseModal()
    navTo.selectyourbank_5()
  }

  return (
    <div className="bootstrap" style={{ position: 'relative' }}>
      {
        isModalOpen &&
        <div className="modal show" tabIndex="-1" dataBsBackdrop="static" style={{ display: 'block', backgroundColor: 'rgba(33,37,41,0.3)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <Text.H3 style={{ margin: 'auto' }}>Data holder status</Text.H3 >
                <Images.CloseButtonIcon onClick={() => goBackToSelectBank()} className="cursor-pointer" />
              </div>
              <div className="modal-body">
                <Text.H5 style={{ lineHeight: '50px' }}>Data holder status is not equal to "Ok", do you want to proceed?</Text.H5 >
                <div className="container">
                  <b>Current status:</b> {message}
                </div>
                <WhiteSection flex="col nogrow pt-3" style={{ position: 'relative', marginTop: '5px' }}>
                  <div className="row nogrow" style={{ justifyContent: "center" }}>
                    <ButtonOutlineNoPad
                      style={{ marginLeft: '12.5px', marginRight: '15px', borderRadius: '28px', padding: '0% !important' }}
                      onClick={() => goBackToSelectBank()}>Close</ButtonOutlineNoPad>

                    <ButtonGreenNoPad
                      style={{ marginLeft: '12.5px', marginRight: '15px', borderRadius: '28px', padding: '0% !important' }}
                      onClick={() => fnProccedOn()}>Continue anyway</ButtonGreenNoPad>
                  </div>
                </WhiteSection>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const ConnectWithBank = (props) => {

  const userDetail = api.useGetProMember()

  let { location: { state = {} } = {} } = props
  if (state === null) state = {}
  const { bank = '[no bank provided]', checkList = {}, durationSec, par, requestVersion } = state
  const bankUri = bank === '[no bank provided]' ? null : bank.logoUri
  const [isLoading, setIsloading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)
  const { amendConsent, setAmendConsent } = React.useContext(GlobalStoreContext)
  const [isDHModalOpen, setIsDHModalOpen] = React.useState(false)

  let { isLoading: userIsLoading, data: userData = null, isSuccess: userIsSuccess } = useQuery('users', async () => {
    return new Promise((resolve, reject) => {
      api.getUserDetail().then((returnData) => resolve(returnData.data)).catch(err => reject(err))
    })
  }, {
    retry: 1,
    refetchOnWindowFocus: false
  })

  const { data: redirectData, isLoading: urlIsLoading } = useQuery('redirectUrl', async () => {
    const { data } = await api.getPublicRedirectBaseUrl()
    return data
  }, {
    refetchOnWindowFocus: true,
    staleTime: 5 * 60 * 1000, // 5mins
    onError: (error) => {
      setErrorMessage("Unable to fetch redirect url.")
    }
  })

  console.log("bank.dataHolderBrandId", bank.dataHolderBrandId)

  const { data: dataHolderStatus, isLoading: dataHolderStatusIsLoading } = useQuery(`dataholderStatus-${bank.dataHolderBrandId}`, async () => {
    const { data } = await api.dataHolderStatus(bank.dataHolderBrandId)
    return data
  }, {
    enabled: typeof bank.dataHolderBrandId !== 'undefined',
    refetchOnWindowFocus: true,
    onError: (error) => {
      setErrorMessage("Unable to fetching data holder status")
    }
  })

  const ConnectBank = (ignoreDHStatus = false) => {
    let clusters = []
    Object.keys(checkList).forEach((x) => {
      checkList[x] && clusters.push(x)
    })

    let payload =
    {
      "dataHolderBrandId": bank.dataHolderBrandId,
      "dataClusters": clusters,
      "redirectUri": redirectData.redirectUrl
    }

    // Add support for customer version
    let customVersionSelected = {}

    // if commercial version
    // add commercial specific payload
    if (userDetail.data?.isPro) {
      payload.durationSec = durationSec
      payload.par = par
      if (amendConsent && amendConsent.bank ) {
        payload.dataHolderBrandId = amendConsent.bank.dataHolderBrandId
        payload.arrangementId = amendConsent.arrangementId
        payload.existingConsentId = amendConsent.existingConsentId
      }
      if(ignoreDHStatus){
        payload.ignoreDHStatus = true
      }
      // check if version any of the additional parameter is not default ('')
      if (requestVersion) {
        Object.keys(requestVersion).forEach(x => {
          const workingCluster = requestVersion[x]
          if (!(workingCluster.version === '' && workingCluster.minVersion === '')) {
            // add cluster that have a specific version
            customVersionSelected[x] = workingCluster
          }
        })
        if(Object.keys(customVersionSelected).length > 0){
          payload.customVersion = customVersionSelected
        }
      }
    }

    // Animation delay
    setTimeout(() => {
      setIsloading(true)
      setErrorMessage(null)
      setAmendConsent(null) // reset amend consent detail
      api.createUserConsent(payload).then(response => {
        console.log("consent success", response?.data?.authoriseUrl)
        setIsloading(false)
        window.location.replace(response?.data?.authoriseUrl)

      }).catch((err) => {
        let { response } = err
        if (response && response.status === 429) {
          setErrorMessage('Too many collections already. Try again later.')
        } else {
          if (response && response.data && response.data.trace) {
            setErrorMessage(response.data.trace)
          } else {
            setErrorMessage("Unable to submit your request, please try again.")
          }

        }
        setIsloading(false)
      })
    }, 300)
  }

  // check if need to oen the data holder status message
  const openDHModalOrConnectBank = () => {
    if (userDetail.data.isPro && dataHolderStatus && dataHolderStatus.status !== 'OK') {
      setIsDHModalOpen(true)
    } else {
      ConnectBank()
    }
  }


  const ConnectWithBank = "Connect with your bank"
  const subText = "Connect with your bank"

  const ContinueButton = () => {
    return (
      <ButtonGreen onClick={() => openDHModalOrConnectBank()}
        style={{ marginBottom: '24px' }} disabled={(isLoading || urlIsLoading || dataHolderStatusIsLoading)}>
        {(!isLoading && !urlIsLoading && !dataHolderStatusIsLoading) && <span>Connect now</span>}
        {(isLoading || urlIsLoading || dataHolderStatusIsLoading) && <span className="bootstrap"><div className="spinner-border text-dark" role="status">
          <span className="sr-only">Loading...</span>
        </div></span>}
      </ButtonGreen>
    )
  }

  const ErrorMessageSection = () => (<>
    {errorMessage && <Flex flex="col nogrow">
      <ErrorBootstrapSection className="bootstrap" style={{ marginBottom: '0px' }}>
        <div className="alert alert-danger" style={{ minWidth: '350px', maxWidth: '500px' }}>{typeof errorMessage === 'string' ? errorMessage : <pre>{JSON.stringify(errorMessage, null, 2)}</pre>}</div>
      </ErrorBootstrapSection>
      <WhiteSection flex="row" style={{ marginTop: '5px' }}>
        <ButtonGreen onClick={() => ButtonDelay(navTo.selectyourbank_5, { ...state })}>Retry</ButtonGreen>
      </WhiteSection>
    </Flex>
    }
  </>)

  return (
    <Layout.ColumnLayout fitToHeightOnMobile={true} title={ConnectWithBank}>
      <HeaderBanner logoUrl={bankUri} withStep={true} hamburger={false} commercial={userIsSuccess} commercialProperties={userData} userIsLoading={userIsLoading}>
        <Breadcrumb from={4} to={6} />
      </HeaderBanner>

      {/* Data holder status not equal to Ok modal */}
      <DataHolderStatusErrorModal
        message={dataHolderStatus?.status}
        isModalOpen={isDHModalOpen}
        fnCloseModal={() => setIsDHModalOpen(false)}
        fnProccedOn={() => {
          setIsDHModalOpen(false)
          ConnectBank(true)
        }} />


      {/* mobile only */}
      <Vis.MobileOnly flex="col">
        <RelativeFlexMobile>
          <MobileFullPageAbsoluteDiv>
            <WhiteSection flex="col nogrow" style={{ margin: '11px 10px 0px 10px' }}>
              <Vis.MobileOnly><Text.Steps>Step 4 of 6</Text.Steps></Vis.MobileOnly>
              <Text.H1 style={{ marginTop: '0px', marginBottom: '16px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{ConnectWithBank}</Text.H1>
              <GreenBarSection style={{ marginBottom: '21px', minWidth: '200px', width: '90%', maxWidth: '450px', color: '#373737', fontFamily: 'Arial' }}>Next, you’ll be asked by <b>{bank.brandName}</b> to authorise us to collect your data for you. To do this, we will now connect you to them <br /> <br /> <b>Note: myCDRdata will never ask for your password to collect CDR data</b></GreenBarSection>
            </WhiteSection>
            <Flex flex="col">
              <MobileHeroSection />
            </Flex>

            <WhiteSection flex="row nogrow hcenter" style={{ marginTop: '20px' }}>
              {errorMessage === null && <ContinueButton />}
              <ErrorMessageSection />
            </WhiteSection>
          </MobileFullPageAbsoluteDiv>
        </RelativeFlexMobile>
      </Vis.MobileOnly>

      {/* Tablet */}

      <Vis.TabletOnly flex="col">
        <WhiteSection flex="col nogrow" style={{ margin: '0px 35px 0px 38px' }}>
          <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{ConnectWithBank}</Text.H1>
          <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>Next, you’ll be asked by <b>{bank.brandName}</b> to authorise us to collect your data for you. To do this, we will now connect you to them <br /> <br /> <b>Note: myCDRdata will never ask for your password to collect CDR data</b></GreenBarSection>
        </WhiteSection>

        <WhiteSection flex="col center" style={{ margin: '0px 100px' }}>
          <ImageHolder alt='' src={Step4HeroSvg} />
        </WhiteSection>

        <WhiteSection flex="row hcenter" style={{ margin: '50px 150px', overflowWrap: 'anywhere' }}>
          {errorMessage === null && <ContinueButton />}
          <ErrorMessageSection />
        </WhiteSection>
      </Vis.TabletOnly>

      {/* Desktop */}
      <Vis.DesktopOnly flex="col">
        <WhiteSection flex="row" style={{ margin: '52px 50px 0px 100px' }}>
          <WhiteSection flex="col w50" style={{ padding: '0px' }}>
            <WhiteSection flex="col nogrow">
              <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{ConnectWithBank}</Text.H1>
              <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial', maxWidth: '480px' }}>Next, you’ll be asked by <b>{bank.brandName}</b> to authorise us to collect your data for you. To do this, we will now connect you to them <br /> <br /> <b>Note: myCDRdata will never ask for your password to collect CDR data</b></GreenBarSection>
            </WhiteSection>
            <WhiteSection flex="row" style={{ marginTop: errorMessage ? "10px" : "50px", padding: "0px 18px", overflowWrap: 'anywhere' }}>
              {errorMessage === null && <div style={{ width: '350px', display: 'flex' }}><ContinueButton /></div>}
              <ErrorMessageSection />
            </WhiteSection>
          </WhiteSection>
          <Flex flex="w50 col hcenter" >
            <WhiteSection flex="row vstart" style={{ width: '100%' }}>
              <ImageHolder alt='' src={Step4HeroSvg} />
            </WhiteSection>
          </Flex>
        </WhiteSection>
        {/* {
          isCommercial &&
          <WhiteSection flex="row nogrow highlight" style={{ margin: '0px 50px 0px 100px', maxWidth: '1000px' }}>
            <PreWrap style={{ padding: '10px 10px', backgroundColor: 'lightgrey', maxWidth: '980px' }}>
              Duration: {durationSec}, Pushed Authroisation Request:{JSON.stringify(par)}
              {`\n`}Bank: {JSON.stringify(bank.brandName)}
              {`\n`}Checklist: {JSON.stringify(checkList)}</PreWrap>
          </WhiteSection>
        } */}
      </Vis.DesktopOnly>

    </Layout.ColumnLayout>
  )
}

export default ConnectWithBank
